/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { DOCS_BASE_URL } from '../constants';
import { stripTrailingSlash } from '../utilities';

// function to be inlined in the head in a script tag
function handleLegacyDocsRedirects() {
  const redirectPaths = {
    '#api-keys-and-access': '/api/',
    '#api-protocols': '/api/',
    '#api-host': '/api/',
    '#endpoints': '/api/',
    '#product-access-endpoints': '/api/',
    '#versioning': '/api/versioning/',
    '#breaking-changes': '/api/versioning/',
    '#we-consider-the-following-changes-to-be-backwards-compatible-':
      'api/versioning/',
    '#system-status': '/api/#api-status',
    '#in-the-dashboard': '/account/',
    '#via-the-api': '/api/institutions/#institutionsget_by_id',
    '#creating-items-with-plaid-link': '/link/',
    '#integrating-with-link': '/link/web/',
    '#client-side-link-integration': '/link/web/',
    '#parameter-reference': '/link/web/#create',
    '#onsuccess-callback': '/link/web/#onsuccess',
    '#onsuccess-example': '/link/web/#onsuccess',
    '#onsuccess-metadata-schema': '/link/web/#onsuccess',
    '#onexit-callback': '/link/web/#onexit',
    '#metadata-status': '/link/web/#onexit',
    '#onexit-example': '/link/web/#onexit',
    '#error-object-schema': '/link/web/#onexit',
    '#onexit-metadata-schema': '/link/web/#onexit',
    '#onevent-callback': '/link/web/#onevent',
    '#event-names': '/link/web/#onevent',
    '#metadata-reference': '/link/web/#onevent',
    '#metadata-view_name': '/link/web/#onevent',
    '#onevent-example': '/link/web/#onevent',
    '#eventnames-enum': '/link/web/#onevent',
    '#onevent-metadata-schema': '/link/web/#onevent',
    '#open-function': '/link/web/#onevent',
    '#open-link': '/link/web/#open',
    '#exit-function': '/link/web/#exit',
    '#exit-function-example': '/link/web/#exit',
    '#exit-example': '/link/web/#exit',
    '#destroy-function': '/link/web/#destroy',
    '#destroy-function-example': '/link/web/#destroy',
    '#destroy-example': '/link/web/#destroy',
    '#create-link-token': '/api/link/#linktokencreate',
    '#link-token-user': '/api/link/#linktokencreate',
    '#link-token-payment-initiation': '/api/link/#linktokencreate',
    '#link-token': '/api/link/#linktokencreate',
    '#link-token-request': '/api/link/#linktokencreate',
    '#link-token-response': '/api/link/#linktokencreate',
    '#account-filters': '/api/link/#linktokencreate',
    '#accounts-filter-example': '/api/link/#linktokencreate',
    '#exchange-token-flow': '/api/link/#itempublic_tokenexchange',
    '#exchange-token': '/api/link/#itempublic_tokenexchange',
    '#exchange-token-request': 'api/link/#itempublic_tokenexchange',
    '#exchange-token-response': 'api/link/#itempublic_tokenexchange',
    '#link-token-for-update-mode': '/link/update-mode/',
    '#create-link-token-for-update': '/link/update-mode/',
    '#create-link-token-for-update-request': '/link/update-mode/',
    '#createPublicTokenExample': '/api/link/#linktokencreate',
    '#create-link-token-for-update-response': 'api/link/#linktokencreate',
    '#createPublicTokenResponse': '/api/link/#linktokencreate',
    '#updating-items-via-link': '/link/update-mode/',
    '#initialize-link-in-update-mode': '/link/update-mode/',
    '#init-link-in-update-mode': '/link/update-mode/',
    '#ios-bindings': '/link/ios/',
    '#webview-integration': '/link/webview/',
    '#link-webview-initialization-url': '/link/webview/',
    '#communication-between-link-and-your-app':
      'link/webview/#communication-between-link-and-your-app',
    '#connected-event': '/link/webview/#connected',
    '#connected-event-example': '/link/webview/#connected',
    '#exit-event': '/link/webview/#exit',
    '#exit-event-example': '/link/webview/#exit',
    '#event-event': '/link/webview/#event',
    '#event-event-example': '/link/webview/#event',
    '#webview-examples': '/link/webview/#create',
    '#example-link-webview-initialization-url': '/link/webview/#create',
    '#example-link-webview-update-mode-initialization-url':
      '/link/webview/#create',
    '#oauth': '/link/oauth/',
    '#oauth-mobile-web-browser-and-webview': 'link/oauth/#mobile-web-or-react',
    '#oauth-ios': '/link/oauth/#ios',
    '#oauth-redirect-uris': '/link/oauth/#redirect-uri-configuration',
    '#browser-support': '/link/web/#supported-browsers',
    '#desktop': '/link/web/#supported-browsers',
    '#ios': '/link/ios/#installation',
    '#android': '/link/android/#requirements',
    '#item-product-access': '/',
    '#auth': '/auth/',
    '#enabling-all-auth-features': '/auth/coverage/',
    '#verifying-same-day-microdeposits': '/auth/coverage/same-day/',
    '#retrieve-auth-request': '/api/products/#authget',
    '#retrieve-auth-request-example': '/api/products/#authget',
    '#retrieve-auth-response': '/api/products/#authget',
    '#retrieve-auth-response-example': '/api/products/#authget',
    '#ach-numbers-schema': '/api/products/#authget',
    '#eft-numbers-schema': '/api/products/#authget',
    '#international-numbers-schema': '/api/products/#authget',
    '#bacs-numbers-schema': '/api/products/#authget',
    '#transactions': '/transactions/',
    '#retrieve-transactions-request': '/api/products/#transactionsget',
    '#retrieve-transactions-request-example': '/api/products/#transactionsget',
    '#retrieve-transactions-response': '/api/products/#transactionsget',
    '#retrieve-transactions-response-example': '/api/products/#transactionsget',
    '#transaction-schema': '/api/products/#transactionsget',
    '#transaction-location-schema': '/api/products/#transactionsget',
    '#transaction-payment_meta-schema': '/api/products/#transactionsget',
    '#item-transaction-updates': '/api/products/#transactionsget',
    '#full-transaction-history': '/api/products/#transactionsget',
    '#transactions-refresh': '/api/products/#transactionsrefresh',
    '#balance': '/balance/',
    '#retrieve-balance-request': '/api/products/#accountsbalanceget',
    '#retrieve-balance-request-example': '/api/products/#accountsbalanceget',
    '#retrieve-balance-response': '/api/products/#accountsbalanceget',
    '#retrieve-balance-response-example': '/api/products/#accountsbalanceget',
    '#balances-schema': '/api/products/#accountsbalanceget',
    '#identity': '/identity/',
    '#retrieve-identity-request': '/api/products/#identityget',
    '#retrieve-identity-request-example': '/api/products/#identityget',
    '#retrieve-identity-response': '/api/products/#identityget',
    '#retrieve-identity-response-example': '/api/products/#identityget',
    '#identity-schema': '/api/products/#identityget',
    '#phone-numbers-schema': '/api/products/#identityget',
    '#emails-schema': '/api/products/#identityget',
    '#addresses-schema': '/api/products/#identityget',
    '#address-data-schema': '/api/products/#identityget',
    '#assets': '/assets/',
    '#1-create-items': '/assets/add-to-app/',
    '#2-create-an-asset-report': '/assets/add-to-app/',
    '#create-asset-report-request': '/api/products/#asset_reportcreate',
    '#create-asset-report-request-example': '/api/products/#asset_reportcreate',
    '#create-asset-report-request-parameters':
      '/api/products/#asset_reportcreate',
    '#options-object': '/api/products/#asset_reportcreate',
    '#user-object': '/api/products/#asset_reportcreate',
    '#create-asset-report-response': '/api/products/#asset_reportcreate',
    '#create-asset-report-response-example':
      '/api/products/#asset_reportcreate',
    '#refreshing-an-asset-report': '/api/products/#asset_reportrefresh',
    '#refresh-asset-report-request': '/api/products/#asset_reportrefresh',
    '#refresh-asset-report-request-example':
      '/api/products/#asset_reportrefresh',
    '#refresh-asset-report-response': '/api/products/#asset_reportrefresh',
    '#refresh-asset-report-response-example':
      '/api/products/#asset_reportrefresh',
    '#specifying-which-accounts-appear-in-the-asset-report':
      '/api/products/#asset_reportfilter',
    '#filter-asset-report-request': '/api/products/#asset_reportfilter',
    '#filter-asset-report-request-example': '/api/products/#asset_reportfilter',
    '#filter-asset-report-response': '/api/products/#asset_reportfilter',
    '#filter-income-response-example': '/api/products/#asset_reportfilter',
    '#3-retrieve-an-asset-report': '/api/products/#asset_reportget',
    '#retrieve-json-report-request': '/api/products/#asset_reportget',
    '#retrieve-json-asset-report-request': '/api/products/#asset_reportget',
    '#retrieve-json-report-request-example': '/api/products/#asset_reportget',
    '#retrieve-json-asset-report-response': '/api/products/#asset_reportget',
    '#retrieve-json-report-response-example': '/api/products/#asset_reportget',
    '#retrieve-json-report-response-data-elements':
      '/api/products/#asset_reportget',
    '#report-object': '/api/products/#asset_reportget',
    '#item-object': '/api/products/#asset_reportget',
    '#account-object': '/api/products/#asset_reportget',
    '#owner-object': '/api/products/#asset_reportget',
    '#phone-number-object': '/api/products/#asset_reportget',
    '#email-object': '/api/products/#asset_reportget',
    '#address-object': '/api/products/#asset_reportget',
    '#address-data-object': '/api/products/#asset_reportget',
    '#balance-object': '/api/products/#asset_reportget',
    '#unofficial-currency-codes': '/api/products/#asset_reportget',
    '#historical-balance-object': '/api/products/#asset_reportget',
    '#transaction-object': '/api/products/#asset_reportget',
    '#warning-object': '/api/products/#asset_reportget',
    '#cause-object': '/api/products/#asset_reportget',
    '#retrieve-pdf-report-request': '/api/products/#asset_reportpdfget',
    '#retrieve-pdf-report-request-example': '/api/products/#asset_reportpdfget',
    '#4-share-an-audit-copy': '/api/products/#asset_reportaudit_copycreate',
    '#create-audit-copy-request': '/api/products/#asset_reportaudit_copycreate',
    '#create-audit-copy-request-example':
      '/api/products/#asset_reportaudit_copycreate',
    '#create-audit-copy-response':
      '/api/products/#asset_reportaudit_copycreate',
    '#create-audit-copy-response-example':
      '/api/products/#asset_reportaudit_copycreate',
    '#additional-fannie-mae-day-1-certainty-considerations':
      '/api/products/#asset_reportcreate',
    '#remove-asset-reports-and-audit-copies':
      '/api/products/#asset_reportremove',
    '#remove-asset-report-request': '/api/products/#asset_reportremove',
    '#remove-asset-report-request-example': '/api/products/#asset_reportremove',
    '#remove-asset-report-response': '/api/products/#asset_reportremove',
    '#remove-asset-report-response-example':
      '/api/products/#asset_reportremove',
    '#remove-audit-copy-request': '/api/products/#asset_reportaudit_copyremove',
    '#remove-audit-copy-request-example':
      '/api/products/#asset_reportaudit_copyremove',
    '#remove-audit-copy-response':
      '/api/products/#asset_reportaudit_copyremove',
    '#investments': '/investments/',
    '#retrieve-holdings-request': '/api/products/#investmentsholdingsget',
    '#retrieve-investment-holdings-request-example':
      '/api/products/#investmentsholdingsget',
    '#retrieve-investemnt-holdings-request-example':
      '/api/products/#investmentsholdingsget',
    '#retrieve-investment-holdings-response-example':
      '/api/products/#investmentsholdingsget',
    '#security-schema': '/api/products/#investmentsholdingsget',
    '#holding-schema': '/api/products/#investmentsholdingsget',
    '#investment-security-types': '/api/products/#investmentsholdingsget',
    '#retrieve-investment-transactions-request':
      '/api/products/#investmentstransactionsget',
    '#retrieve-investment-transactions-request-example':
      '/api/products/#investmentstransactionsget',
    '#retrieve-investemnt-transactions-request-example':
      '/api/products/#investmentstransactionsget',
    '#retrieve-investment-transactions-response-example':
      '/api/products/#investmentstransactionsget',
    '#investment-transaction-schema':
      '/api/products/#investmentstransactionsget',
    '#investment-transaction-types':
      '/api/products/#investmentstransactionsget',
    '#investment-transaction-subtypes':
      '/api/products/#investmentstransactionsget',
    '#liabilities': '/liabilities/',
    '#liabilities-quick-links': '/liabilities/',
    '#creating-liabilities-items': '/liabilities/add-to-app/',
    '#create-liabilities-items': '/liabilities/add-to-app/',
    '#supported-liabilities-account-types': '/api/products/#liabilitiesget',
    '#updating-liabilities-items': '/api/products/#liabilitiesget',
    '#liabilities-payment-history': '/api/products/#liabilitiesget',
    '#retrieve-liabilities-request': '/api/products/#liabilitiesget',
    '#retrieve-liabilities-request-example': '/api/products/#liabilitiesget',
    '#retrieve-liabilities-response-student': '/api/products/#liabilitiesget',
    '#student-loan-liability-schema': '/api/products/#liabilitiesget',
    '#student-loan-status-schema': '/api/products/#liabilitiesget',
    '#student-repayment-plan-schema': '/api/products/#liabilitiesget',
    '#pslf-status-schema': '/api/products/#liabilitiesget',
    '#servicer-address-schema': '/api/products/#liabilitiesget',
    '#retrieve-liabilities-response-credit': '/api/products/#liabilitiesget',
    '#credit-card-liability-schema': '/api/products/#liabilitiesget',
    '#apr-schema': '/api/products/#liabilitiesget',
    '#payment-initiation': '/payment-initiation/',
    '#single-immediate-payments': '/payment-initiation/intro/',
    '#standing-orders': '/api/products/#payment_initiationpaymentcreate',
    '#payment-initiation-quick-links': '/payment-initiation/',
    '#payment-initiation-ios': '/payment-initiation/intro/',
    '#payment-initiation-high-level-flow': '/payment-initiation/intro/',
    '#payment-initiation-initializing-link': '/payment-initiation/intro/',
    '#payment-initiation-link': '/payment-initiation/intro/',
    '#payment-initiation-create-recipient-request':
      '/api/products/#payment_initiationrecipientcreate',
    '#payment-initation-create-recipient-request':
      '/api/products/#payment_initiationrecipientcreate',
    '#payment-initation-create-recipient-response':
      '/api/products/#payment_initiationrecipientcreate',
    '#payment-initiation-get-recipient-request':
      '/api/products/#payment_initiationrecipientget',
    '#payment-initation-get-recipient-request':
      '/api/products/#payment_initiationrecipientget',
    '#payment-initation-get-recipient-response':
      '/api/products/#payment_initiationrecipientget',
    '#recipient-schema': '/api/products/#payment_initiationrecipientget',
    '#payment-initiation-list-recipients-request':
      '/api/products/#payment_initiationrecipientlist',
    '#payment-initation-list-recipients-request':
      '/api/products/#payment_initiationrecipientlist',
    '#payment-initation-list-recipients-response':
      '/api/products/#payment_initiationrecipientlist',
    '#payment-initiation-create-payment-request':
      '/api/products/#payment_initiationpaymentcreate',
    '#payment-initation-create-payment-request':
      '/api/products/#payment_initiationpaymentcreate',
    '#payment-initation-create-payment-response':
      '/api/products/#payment_initiationpaymentcreate',
    '#payment-initiation-create-link-token-request':
      '/api/link/#linktokencreate',
    '#payment-initation-create-link-token-request':
      '/api/link/#linktokencreate',
    '#payment-initation-create-link-token-response':
      '/api/link/#linktokencreate',
    '#payment-initiation-get-payment-request':
      '/api/products/#payment_initiationpaymentget',
    '#payment-initation-get-payment-request':
      '/api/products/#payment_initiationpaymentget',
    '#payment-initation-get-payment-response':
      '/api/products/#payment_initiationpaymentget',
    '#payment-schema': '/api/products/#payment_initiationpaymentget',
    '#payment-statuses': '/api/products/#payment_initiationpaymentget',
    '#payment-initiation-list-payments-request':
      '/api/products/#payment_initiationpaymentlist',
    '#payment-initation-list-payments-request':
      '/api/products/#payment_initiationpaymentlist',
    '#payment-initation-list-payments-response':
      '/api/products/#payment_initiationpaymentlist',
    '#item-management': '/api/items/',
    '#accounts': '/api/accounts/',
    '#retrieve-accounts-request': '/api/accounts/#accountsget',
    '#retrieve-accounts-request-example': '/api/accounts/#accountsget',
    '#retrieve-accounts-response': '/api/accounts/#accountsget',
    '#retrieve-accounts-response-example': '/api/accounts/#accountsget',
    '#account-schema': '/api/accounts/#accountsget',
    '#account-balances-schema': '/api/accounts/#accountsget',
    '#account-types': '/api/accounts/#account-type-schema',
    '#account-subtypes': '/api/accounts/#account-type-schema',
    '#account-subtypes-investment': '/api/accounts/#account-type-schema',
    '#account-verification-statuses': '/api/accounts/#account-type-schema',
    '#retrieve-item': '/api/items/#itemget',
    '#retrieve-item-request': '/api/items/#itemget',
    '#retrieve-item-request-example': '/api/items/#itemget',
    '#retrieve-item-response': '/api/items/#itemget',
    '#retrieve-item-response-example': '/api/items/#itemget',
    '#response-schema': '/api/items/#itemget',
    '#item-schema': '/api/items/#itemget',
    '#item-error-schema': '/api/items/#itemget',
    '#update-webhook': '/api/items/#itemwebhookupdate',
    '#update-webhook-request': '/api/items/#itemwebhookupdate',
    '#update-webhook-request-example': '/api/items/#itemwebhookupdate',
    '#update-webhook-response': '/api/items/#itemwebhookupdate',
    '#update-webhook-response-example': '/api/items/#itemwebhookupdate',
    '#rotate-access-token': '/api/items/#itemaccess_tokeninvalidate',
    '#rotate-access-token-request': '/api/items/#itemaccess_tokeninvalidate',
    '#rotate-access-token-request-example':
      '/api/items/#itemaccess_tokeninvalidate',
    '#rotate-access-token-response': '/api/items/#itemaccess_tokeninvalidate',
    '#rotate-access-token-response-example':
      '/api/items/#itemaccess_tokeninvalidate',
    '#remove-an-item': '/api/items/#itemremove',
    '#remove-item-request': '/api/items/#itemremove',
    '#remove-item-request-example': '/api/items/#itemremove',
    '#remove-item-response': '/api/items/#itemremove',
    '#remove-item-response-example': '/api/items/#itemremove',
    '#item-status': '/api/items/#itemget',
    '#item-status-example': '/api/items/#itemget',
    '#item-status-schema': '/api/items/#itemget',
    '#investments-status-schema': '/api/items/#itemget',
    '#transactions-status-schema': '/api/items/#itemget',
    '#webhook-status-schema': '/api/items/#itemget',
    '#errors': '/errors/',
    '#errors-overview': '/errors/',
    '#error-schema': '/errors/#error-schema',
    '#invalid-request-errors': '/errors/invalid-request/',
    '#invalid-input-errors': '/errors/invalid-input/',
    '#rate-limit-exceeded-errors': '/errors/rate-limit-exceeded/',
    '#rate-limit-errors': '/errors/rate-limit-exceeded/',
    '#api-errors': '/errors/api/',
    '#api-error-schema': '/errors/api/',
    '#item-errors': '/errors/item/',
    '#auth-errors': '/errors/item/',
    '#auth-error-schema': '/errors/item/',
    '#asset-report-errors-and-warnings': '/errors/assets/',
    '#asset-report-error-schema': '/errors/assets/',
    '#asset-report-warning-schema': '/errors/assets/',
    '#institution-errors': '/errors/institution/',
    '#institution-error-schema': '/errors/institution/',
    '#webhooks': '/api/webhooks/',
    '#auth-webhooks': '/api/webhooks/#auth-webhooks',
    '#automated-microdeposit-successful-webhook':
      '/api/webhooks/#auth-automatically_verified',
    '#automated-microdeposit-verification-failed-webhook':
      '/api/webhooks/#auth-verification_expired',
    '#transactions-webhooks': '/api/webhooks/#transaction-webhooks',
    '#initial-transaction-webhook':
      '/api/webhooks/#transactions-initial_update',
    '#historical-transaction-webhook':
      '/api/webhooks/#transactions-historical_update',
    '#default-transaction-webhook':
      '/api/webhooks/#transactions-default_update',
    '#removed-transaction-webhook':
      '/api/webhooks/#transactions-transactions_removed',
    '#item-webhooks': '/api/webhooks/#item-webhooks',
    '#item-webhook-updated-webhook':
      '/api/webhooks/#item-webhook_update_acknowledged',
    '#item-pending-expiration-webhook':
      '/api/webhooks/#item-pending_expiration',
    '#item-user-permission-revoked-webhook':
      '/api/webhooks/#item-user_permission_revoked',
    '#item-error-webhook': '/api/webhooks/#item-error',
    '#assets-webhooks': '/api/webhooks/#assets-webhooks',
    '#asset-report-generation-successful-webhook':
      '/api/webhooks/#assets-product_ready',
    '#asset-report-generation-failed-webhook': '/api/webhooks/#assets-error',
    '#investments-webhooks': '/api/webhooks/#investments-webhooks',
    '#investments-holdings-webhook': '/api/webhooks/#holdings-default_update',
    '#investments-transactions-webhook':
      '/api/webhooks/#investments_transactions-default_update',
    '#payment-initiation-webhooks':
      '/api/webhooks/#payment-initiation-webhooks',
    '#webhook-verification': '/api/webhook-verification/',
    '#steps-for-verification': '/api/webhook-verification/',
    '#plaid-verification-header': '/api/webhook-verification/',
    '#webhook-verification-key-endpoint':
      '/api/webhook-verification/#webhook_verification_keyget',
    '#code-webhook-verification-key-get-example':
      '/api/webhook-verification/#webhook_verification_keyget',
    '#webhook-verification-key-response':
      '/api/webhook-verification/#webhook_verification_keyget',
    '#webhook-verification-payload':
      '/api/webhook-verification/#webhook_verification_keyget',
    '#webhook-key-rotation-and-caching':
      '/api/webhook-verification/#caching-and-key-rotation',
    '#institutions': '/api/institutions/',
    '#institution-overview': '/api/institutions/',
    '#institution-search': '/api/institutions/#institutionssearch',
    '#institution-search-request': '/api/institutions/#institutionssearch',
    '#institutions-search-request': '/api/institutions/#institutionssearch',
    '#search-by-bank-product-example': '/api/institutions/#institutionssearch',
    '#institutions-search-response': '/api/institutions/#institutionssearch',
    '#search-by-bank-product-response-example':
      '/api/institutions/#institutionssearch',
    '#all-institutions': '/api/institutions/#institutionsget',
    '#retrieve-all-institutions-request': '/api/institutions/#institutionsget',
    '#all-institutions-request': '/api/institutions/#institutionsget',
    '#retrieve-all-institutions-request-example':
      '/api/institutions/#institutionsget',
    '#all-institutions-response': '/api/institutions/#institutionsget',
    '#retrieve-all-institutions-response-example':
      '/api/institutions/#institutionsget',
    '#institutions-by-id': '/api/institutions/#institutionsget_by_id',
    '#institutions-by-id-request': '/api/institutions/#institutionsget_by_id',
    '#retrieve-institutions-by-id-request':
      '/api/institutions/#institutionsget_by_id',
    '#institutions-by-id-request-example':
      '/api/institutions/#institutionsget_by_id',
    '#retrieve-institutions-by-id-response':
      '/api/institutions/#institutionsget_by_id',
    '#institutions-by-id-response-example':
      '/api/institutions/#institutionsget_by_id',
    '#institution-schema': '/api/institutions/#institutionsget_by_id',
    '#institution-status-schema': '/api/institutions/#institutionsget_by_id',
    '#institution-status': '/api/institutions/#institutionsget_by_id',
    '#institution-status-example': '/api/institutions/#institutionsget_by_id',
    '#item-logins-status-schema': '/api/institutions/#institutionsget_by_id',
    '#item-logins-breakdown-status-schema':
      '/api/institutions/#institutionsget_by_id',
    '#institution-status-in-link': '/link/institution-status/',
    '#transactions-updates-status-schema':
      '/api/institutions/#institutionsget_by_id',
    '#transactions-updates-breakdown-status-schema':
      '/api/institutions/#institutionsget_by_id',
    '#auth-status-schema': '/api/institutions/#institutionsget_by_id',
    '#auth-breakdown-status-schema': '/api/institutions/#institutionsget_by_id',
    '#balance-status-schema': '/api/institutions/#institutionsget_by_id',
    '#balance-breakdown-status-schema':
      '/api/institutions/#institutionsget_by_id',
    '#identity-status-schema': '/api/institutions/#institutionsget_by_id',
    '#identity-breakdown-status-schema':
      '/api/institutions/#institutionsget_by_id',
    '#categories': '/api/products/#categoriesget',
    '#category-overview': '/api/products/#categoriesget',
    '#all-categories-request': '/api/products/#categoriesget',
    '#all-categories-request-example': '/api/products/#categoriesget',
    '#all-categories-response': '/api/products/#categoriesget',
    '#all-categories-response-example': '/api/products/#categoriesget',
    '#sandbox': '/sandbox/',
    '#creating-items-in-the-sandbox': '/sandbox/',
    '#via-link': '/sandbox/',
    '#programmatically': '/sandbox/#bypassing-link',
    '#create-sandbox-item-request': '/api/sandbox/#sandboxpublic_tokencreate',
    '#create-sandbox-item-request-example':
      '/api/sandbox/#sandboxpublic_tokencreate',
    '#create-sandbox-item-response': '/api/sandbox/#sandboxpublic_tokencreate',
    '#create-sandbox-item-response-example':
      '/api/sandbox/#sandboxpublic_tokencreate',
    '#custom-sandbox': '/sandbox/user-custom/',
    '#sandbox-custom-example': '/sandbox/user-custom/',
    '#creating-an-item-with-randomized-account-and-transaction-data':
      '/sandbox/user-custom/#configuration-object-schema',
    '#creating-an-item-with-a-seed':
      '/sandbox/user-custom/#configuration-object-schema',
    '#configuring-account-balances':
      '/sandbox/user-custom/#configuration-object-schema',
    '#configuring-the-auth-get-response':
      '/sandbox/user-custom/#configuration-object-schema',
    '#configuring-the-transactions-get-response':
      '/sandbox/user-custom/#configuration-object-schema',
    '#configuring-the-identity-get-response':
      '/sandbox/user-custom/#configuration-object-schema',
    '#configuring-the-liabilities-get-response-for-credit-liabilities':
      '/sandbox/user-custom/#configuration-object-schema',
    '#configuring-the-liabilities-get-response-for-student-loan-liabilities':
      '/sandbox/user-custom/#configuration-object-schema',
    '#configuring-the-asset_report-get-and-asset_report-pdf-get-responses':
      '/sandbox/user-custom/#configuration-object-schema',
    '#testing-mfa': '/sandbox/user-custom/#configuration-object-schema',
    '#testing-recaptcha': '/sandbox/user-custom/#configuration-object-schema',
    '#testing-errors': '/sandbox/user-custom/#configuration-object-schema',
    '#custom-sandbox-schema':
      '/sandbox/user-custom/#configuration-object-schema',
    '#override_accounts-schema':
      '/sandbox/user-custom/#configuration-object-schema',
    '#meta-schema': '/sandbox/user-custom/#configuration-object-schema',
    '#numbers-schema': '/sandbox/user-custom/#configuration-object-schema',
    '#transactions-schema': '/sandbox/user-custom/#configuration-object-schema',
    '#sandbox-identity-schema':
      '/sandbox/user-custom/#configuration-object-schema',
    '#identity-phone_numbers-schema':
      '/sandbox/user-custom/#configuration-object-schema',
    '#identity-emails-schema':
      '/sandbox/user-custom/#configuration-object-schema',
    '#identity-addresses-schema':
      '/sandbox/user-custom/#configuration-object-schema',
    '#sandbox-liability-schema':
      '/sandbox/user-custom/#configuration-object-schema',
    '#sandbox-student-loan-repayment-model-schema':
      '/sandbox/user-custom/#configuration-object-schema',
    '#inflow_model-schema': '/sandbox/user-custom/#configuration-object-schema',
    '#mfa-schema': '/sandbox/user-custom/#configuration-object-schema',
    '#recaptcha-schema': '/sandbox/user-custom/#configuration-object-schema',
    '#sandbox-overridable-errors':
      '/sandbox/user-custom/#configuration-object-schema',
    '#sandbox-users':
      '/sandbox/user-custom/#configuring-the-custom-user-account',
    '#test-credentials': '/sandbox/test-credentials/',
    '#testing-the-default-flow':
      '/sandbox/test-credentials/#sandbox-simple-test-credentials',
    '#testing-auth':
      '/sandbox/institutions/#institution-details-for-auth-testing',
    '#integration-tests': '/sandbox/institutions/',
    '#managing-item-states': '/sandbox/#simulating-data-and-events',
    '#simulating-item_login_required-errors':
      '/sandbox/#simulating-data-and-events',
    '#simulate-item-error': '/sandbox/#simulating-data-and-events',
    '#sandbox-reset-item-login-request': '/api/sandbox/#sandboxitemreset_login',
    '#sandbox-reset-item-login-request-example':
      '/api/sandbox/#sandboxitemreset_login',
    '#sandbox-reset-item-login-response':
      '/api/sandbox/#sandboxitemreset_login',
    '#sandbox-reset-item-login-response-example':
      '/api/sandbox/#sandboxitemreset_login',
    '#firing-webhooks': '/sandbox/#webhooks',
    '#fire-webhook-request': '/api/sandbox/#sandboxitemfire_webhook',
    '#sandbox-fire-webhook-request-example':
      '/api/sandbox/#sandboxitemfire_webhook',
    '#fire-webhook-response': '/api/sandbox/#sandboxitemfire_webhook',
    '#sandbox-fire-webhook-response-example':
      '/api/sandbox/#sandboxitemfire_webhook',
    '#set-verification-status-request':
      '/api/sandbox/#sandboxitemset_verification_status',
    '#sandbox-set-verification-status-request-example':
      '/api/sandbox/#sandboxitemset_verification_status',
    '#set-verification-status-response':
      '/api/sandbox/#sandboxitemset_verification_status',
    '#sandbox-set-verification-status-response-example':
      '/api/sandbox/#sandboxitemset_verification_status',
    '#storing-plaid-api-identifiers': '/launch-checklist/#storage--logging',
    '#plaid-tokens-public_token-access_token-or-asset_report_token':
      '/quickstart/glossary/#tokens-identifiers-and-keys',
    '#account_id': '/launch-checklist/#storage--logging',
    '#item_id': '/launch-checklist/#storage--logging',
  };
  const hash = window.location.hash;
  const redirect = redirectPaths[hash];
  if (redirect != null) {
    window.location.href = `/docs${redirect}`;
  }
}

function handleNonMainPageRedirects() {
  if (window.location.href.includes('docs-beta')) {
    window.location.href = window.location.href.replace('docs-beta', 'docs');
    return;
  }
  const redirectPaths = {
    '/achq/': '/auth/partnerships/achq/',
    '/api/#storing-plaid-api-identifiers':
      '/launch-checklist/#storage--logging',
    '/api-upgrades/': '/api/versioning/',
    '/auth/partnerships/ocrolus/': '/assets/partnerships/ocrolus/',
    '/faq#does-plaid-support-international-bank-accounts-':
      'api/institutions/#institution-coverage-by-country',
    '/drivewealth/': '/auth/partnerships/drivewealth/',
    '/dwolla/': '/auth/partnerships/dwolla/',
    '/extended/': '/api/',
    '/galileo/': '/auth/partnerships/galileo/',
    '/integrations/dwolla/': '/auth/partnerships/dwolla/',
    '/integrations/stripe/': '/auth/partnerships/stripe/',
    '/legacy/resources/': '/api/libraries/',
    '/libraries/': '/api/libraries/',
    '/link-token-migration-guide/': '/link/link-token-migration-guide/',
    '/maintain-legacy-integration/': '/link/maintain-legacy-integration/',
    '/modern-treasury/': '/auth/partnerships/modern-treasury/',
    '/ocrolus/': '/assets/partnerships/ocrolus/',
    '/prime-trust/': '/auth/partnerships/prime-trust/',
    '/processor/': '/auth/partnerships/',
    '/stripe/': '/auth/partnerships/stripe/',
    '/upgrade-to-link-tokens/': '/link/link-token-migration-guide/',
    '/vesta/': '/auth/partnerships/vesta/',
    '/vopay/': '/auth/partnerships/vopay/',
    '/virtual-accounts/': '/payment-initiation/virtual-accounts',
    '/virtual-accounts/managing-virtual-accounts':
      '/payment-initiation/virtual-accounts/managing-virtual-accounts',
    '/virtual-accounts/add-to-app':
      '/payment-initiation/virtual-accounts/add-to-app',
    '/virtual-accounts/payouts/':
      '/payment-initiation/virtual-accounts/payouts',
    '/virtual-accounts/refunds/':
      '/payment-initiation/virtual-accounts/refunds',
    '/virtual-accounts/account-sweeping/':
      '/payment-initiation/virtual-accounts/account-sweeping',
    '/virtual-accounts/payment-confirmation':
      '/payment-initiation/virtual-accounts/payment-confirmation',
    '/check/api/': '/api/products/check',
    '/check/api/#cracheck_reportbase_reportget':
      '/api/products/check#cracheck_reportbase_reportget',
    '/check/api/#cracheck_reportcreate':
      '/api/products/check##cracheck_reportcreate',
    '/check/api/#cracheck_reportincome_insightsget':
      '/api/products/check#cracheck_reportincome_insightsget',
    '/check/api/#cracheck_reportnetwork_insightsget':
      '/api/products/check#cracheck_reportnetwork_insightsget',
    '/check/api/#cracheck_reportpartner_insightsget':
      '/api/products/check#cracheck_reportpartner_insightsget',
    '/check/api/#cracheck_reportpdfget':
      '/api/products/check#cracheck_reportpdfget',
    '/check/api/#cramonitoring_insightsget':
      '/api/products/check#cramonitoring_insightsget',
    '/check/api/#cramonitoring_insightssubscribe':
      '/api/products/check#cramonitoring_insightssubscribe',
    '/check/api/#cramonitoring_insightsunsubscribe':
      '/api/products/check#cramonitoring_insightsunsubscribe',
    '/check/api/##check_report_ready': '/api/products/check#check_report_ready',
    '/check/api/#check_report_failed':
      '/api/products/check#check_report_failed',
    '/check/api/#insights_updated': '/api/products/check#insights_updated',
    '/legacy/stripe/': '/auth/partnerships/stripe/',
    '/link/remember-me/': '/link/returning-user/',
    '/api/products/#transactionsget':
      '/api/products/transactions/#transactionsget',
    '/api/products/#transactionsrefresh':
      '/api/products/transactions/#transactionsrefresh',
    '/api/products/#categoriesget': '/api/products/transactions/#categoriesget',
    '/api/products/#authget': '/api/products/auth/#authget',
    '/api/products/#accountsbalanceget':
      '/api/products/balance/#accountsbalanceget',
    '/api/products/#identityget': '/api/products/identity/#identityget',
    '/api/products/#asset_reportcreate':
      '/api/products/assets/#asset_reportcreate',
    '/api/products/#asset_reportget': '/api/products/assets/#asset_reportget',
    '/api/products/#asset_reportpdfget':
      '/api/products/assets/#asset_reportpdfget',
    '/api/products/#asset_reportrefresh':
      '/api/products/assets/#asset_reportrefresh',
    '/api/products/#asset_reportfilter':
      '/api/products/assets/#asset_reportfilter',
    '/api/products/#asset_reportremove':
      '/api/products/assets/#asset_reportremove',
    '/api/products/#asset_reportaudit_copycreate':
      '/api/products/assets/#asset_reportaudit_copycreate',
    '/api/products/#asset_reportaudit_copyremove':
      '/api/products/assets/#asset_reportaudit_copyremove',
    '/api/products/#investmentsholdingsget':
      '/api/products/investments/#investmentsholdingsget',
    '/api/products/#investmentstransactionsget':
      '/api/products/investments/#investmentstransactionsget',
    '/api/products/#liabilitiesget':
      '/api/products/liabilities/#liabilitiesget',
    '/api/products/#payment_initiationrecipientcreate':
      '/api/products/payment-initiation/#payment_initiationrecipientcreate',
    '/api/products/#payment_initiationrecipientget':
      '/api/products/payment-initiation/#payment_initiationrecipientget',
    '/api/products/#payment_initiationrecipientlist':
      '/api/products/payment-initiation/#payment_initiationrecipientlist',
    '/api/products/#payment_initiationpaymentcreate':
      '/api/products/payment-initiation/#payment_initiationpaymentcreate',
    '/api/products/#payment_initiationpaymentget':
      '/api/products/payment-initiation/#payment_initiationpaymentget',
    '/api/products/#payment_initiationpaymentlist':
      '/api/products/payment-initiation/#payment_initiationpaymentlist',
    '/api/products/#payment_initiationconsentcreate':
      '/api/products/payment-initiation/#payment_initiationconsentcreate',
    '/api/products/#payment_initiationconsentget':
      '/api/products/payment-initiation/#payment_initiationconsentget',
    '/api/products/#payment_initiationconsentrevoke':
      '/api/products/payment-initiation/#payment_initiationconsentrevoke',
    '/api/products/#payment_initiationconsentpaymentexecute':
      '/api/products/payment-initiation/#payment_initiationconsentpaymentexecute',
    '/api/products/#transferauthorizationcreate':
      '/api/products/transfer/#transferauthorizationcreate',
    '/api/products/#transfercreate': '/api/products/transfer/#transfercreate',
    '/api/products/#transfercancel': '/api/products/transfer/#transfercancel',
    '/api/products/#transferget': '/api/products/transfer/#transferget',
    '/api/products/#transferlist': '/api/products/transfer/#transferlist',
    '/api/products/#transfereventlist':
      '/api/products/transfer/#transfereventlist',
    '/api/products/#transfereventsync':
      '/api/products/transfer/#transfereventsync',
    '/api/products/#transfersweepget':
      '/api/products/transfer/#transfersweepget',
    '/api/products/#transfersweeplist':
      '/api/products/transfer/#transfersweeplist',
    '/api/products/#transferintentcreate':
      '/api/products/transfer/#transferintentcreate',
    '/api/products/#transferintentget':
      '/api/products/transfer/#transferintentget',
    '/api/products/#incomeverificationprecheck':
      '/api/products/income/#incomeverificationprecheck',
    '/api/products/#incomeverificationpaystubsget':
      '/api/products/income/#incomeverificationpaystubsget',
    '/api/products/#incomeverificationdocumentsdownload':
      '/api/products/income/#incomeverificationdocumentsdownload',
    '/api/products/#incomeverificationtaxformsget':
      '/api/products/income/#incomeverificationtaxformsget',
    '/api/products/#employmentverificationget':
      '/api/products/income/#employmentverificationget',
    '/api/products/#transactions': '/api/products/transactions',
    '/api/products/#auth': '/api/products/auth',
    '/api/products/#balance': '/api/products/balance',
    '/api/products/#identity': '/api/products/identity',
    '/api/products/#assets': '/api/products/assets',
    '/api/products/#investments': '/api/products/investments',
    '/api/products/#liabilities': '/api/products/liabilities',
    '/api/products/#payment-initiation-uk-and-europe':
      '/api/products/payment-initiation',
    '/api/products/#transfer-beta': '/api/products/transfer',
    '/api/products/#income': '/api/products/income',
    '/api/tokens/': '/api/link/',
    '/api/tokens/#linktokencreate': '/api/link/#linktokencreate',
    '/api/webhooks/#item-webhooks': '/api/items/#webhooks',
    '/api/webhooks/#item-error': '/api/items/#error',
    '/api/webhooks/#item-new_accounts_available':
      '/api/items/#new_accounts_available',
    '/api/webhooks/#item-pending_expiration': '/api/items/#pending_expiration',
    '/api/webhooks/#item-user_permission_revoked':
      '/api/items/#user_permission_revoked',
    '/api/webhooks/#item-webhook_update_acknowledged':
      '/api/items/#webhook_update_acknowledged',
    '/api/webhooks/#transaction-webhooks':
      '/api/products/transactions/#webhooks',
    '/api/webhooks/#transactions-initial_update':
      '/api/products/transactions/#webhooks',
    '/api/webhooks/#transactions-historical_update':
      '/api/products/transactions/#historical_update',
    '/api/webhooks/#transactions-default_update':
      '/api/products/transactions/#default_update',
    '/api/webhooks/#transactions-transactions_removed':
      '/api/products/transactions/#transactions_removed',
    '/api/webhooks/#auth-webhooks': '/api/products/auth/#webhooks',
    '/api/webhooks/#auth-default-update':
      '/api/products/auth/#auth-default-update',
    '/api/webhooks/#auth-automatically_verified':
      '/api/products/auth/#automatically_verified',
    '/api/webhooks/#auth-verification_expired':
      '/api/products/auth/#verification_expired',
    '/api/webhooks/#assets-webhooks': '/api/products/assets/#webhooks',
    '/api/webhooks/#assets-product_ready':
      '/api/products/assets/#product_ready',
    '/api/webhooks/#assets-error': '/api/products/assets/#error',
    '/api/webhooks/#investments-webhooks':
      '/api/products/investments/#webhooks',
    '/api/webhooks/#holdings-default_update':
      '/api/products/investments/#holdings-default_update',
    '/api/webhooks/#investments_transactions-default_update':
      '/api/products/investments/#investments_transactions-default_update',
    '/api/webhooks/#liabilities-webhooks':
      '/api/products/liabilities/#webhooks',
    '/api/webhooks/#liabilities-default_update':
      '/api/products/liabilities/#default_update',
    '/api/webhooks/#payment-initiation-webhooks':
      '/api/products/payment-initiation/#webhooks',
    '/api/webhooks/#payment_initiation-payment_status_update':
      '/api/products/payment-initiation/#payment_status_update',
    '/api/webhooks/#income-webhooks': '/api/products/income/#webhooks',
    '/quickstart/sample-apps': '/resources#sample-apps',
    '/api/webhooks/#income-income_verification':
      '/api/products/income/#income_verification',
  };
  const normalizeSlashes = (path) =>
    path.charAt(path.length - 1) === '/' ? path : `${path}/`;
  const normalizedPath = normalizeSlashes(
    window.location.pathname.replace('/docs', ''),
  );
  const pathWithHash = normalizedPath + window.location.hash;
  const redirect = redirectPaths[pathWithHash];
  if (redirect != null) {
    window.location.href = `/docs${redirect}`;
  }
}

const LegacyDocsRedirects: React.FC = () => {
  const { asPath } = useRouter();
  return (
    <Head>
      {stripTrailingSlash(asPath) === DOCS_BASE_URL ? (
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `(${handleLegacyDocsRedirects.toString()})();`,
          }}
        ></script>
      ) : (
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `(${handleNonMainPageRedirects.toString()})();`,
          }}
        ></script>
      )}
    </Head>
  );
};

LegacyDocsRedirects.displayName = 'LegacyDocsRedirects';

export default LegacyDocsRedirects;
/* eslint-enable @typescript-eslint/naming-convention */
